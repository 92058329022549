export function Kalender() {
  document.addEventListener("DOMContentLoaded", function () {
    var month = document.getElementById("mec_sf_month_278");
    var year = document.getElementById("mec_sf_year_278");

    var d = new Date();
    var y = d.getFullYear();
    var m = d.getMonth() + 1;

    if (month && year) {
      var options = month.options;
      for (let i = 0; i < options.length; i++) {
        if (parseInt(options[i].value) === m) {
          month.selectedIndex = i;
          break;
        }
      }

      var options_year = year.options;
      for (let j = 0; j < options_year.length; j++) {
        if (parseInt(options_year[j].value) === y) {
          year.selectedIndex = j;
          break;
        }
      }
    }
  });
}
