import $ from 'jquery';
import './style.scss';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import html from './routes/html';

//import beispiel-seite from './routes/beispiel-seite';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common,
  /** Home page */
  home,
  // beachte die geänderte Schreibweise: 
  // beispielSeite
  html
});

/** Load Events */
$(document).ready(() => routes.loadEvents());
