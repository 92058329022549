import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export function startbildText() {
    const startbildTextSections = [...document.getElementsByClassName('section startbild-text')];

    if (startbildTextSections.length) {

        startbildTextSections.map(section => {
            const img = section.getElementsByTagName('figure')[0];

            if (img) {

                gsap.from(img, {
                    scrollTrigger: {
                        trigger: img,
                        start: 'top bottom-=100',
                    },
                    x: '-25%',
                    duration: 1,
                    opacity: 0,
                });

            }
        })
    }
};

export function bildText() {
    const bildTextHeadSections = [...document.getElementsByClassName('section bt')];
    const bildTextNoHeadSections = [...document.getElementsByClassName('section bt-no')];
    const bildTextSections = [...bildTextHeadSections, ...bildTextNoHeadSections];

    if (bildTextSections.length) {

        bildTextSections.map(section => {
            const img = section.getElementsByTagName('figure')[0];

            if (img) {

                gsap.from(img, {
                    scrollTrigger: {
                        trigger: img,
                        start: 'top bottom-=100',
                    },
                    x: '-25%',
                    duration: 1,
                    opacity: 0,
                });

            }
        })
    }
};

export function textBild() {
    const bildTextSections = [...document.getElementsByClassName('section tb')];

    if (bildTextSections.length) {

        bildTextSections.map(section => {
            const img = section.getElementsByTagName('figure')[0];

            if (img) {

                gsap.from(img, {
                    scrollTrigger: {
                        trigger: img,
                        start: 'top bottom-=100',
                    },
                    x: '25%',
                    duration: 1,
                    opacity: 0,
                });

            }
        })
    }
};

export function linksUndRechts() {
    const videoTextSections = [...document.getElementsByClassName('section links-und-rechts')];

    if (videoTextSections.length) {

        videoTextSections.map(section => {
            const columns = [...section.getElementsByClassName('column')];

            columns.map((column, i) => {

                if (i === 0) {

                    gsap.from(column, {
                        scrollTrigger: {
                            trigger: column,
                            start: 'top bottom-=100',
                            end: '+=200',
                        },
                        x: '-15%',
                        duration: 1,
                        opacity: 0
                    });

                } else {

                    gsap.from(column, {
                        scrollTrigger: {
                            trigger: column,
                            start: 'top bottom-=100',
                            end: '+=200',
                        },
                        x: '15%',
                        duration: 1,
                    });
                }
            })
        });
    }
};

export function fadeFromBottom() {
    const fadeSections = [...document.getElementsByClassName('fade-from-bottom')];

    if (fadeSections.length) {

        fadeSections.map(section => {
            const items = [...section.getElementsByClassName('fade-item')];

            gsap.defaults({ ease: "power4", duration: 1 });
            gsap.set('.fade-item', { y: '15%', opacity: 0 });

            ScrollTrigger.batch('.fade-item', {
                onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.2, overwrite: true }),
            });

        });
    }
};

export function loopAnimation() {
    const loopSections = [...document.getElementsByClassName('section loop-animation')];

    if (loopSections.length) {

        loopSections.map(section => {
            const columns = [...section.getElementsByClassName('column')];

            if (columns) {

                columns.map(column => column.classList.add('slide-animation'));

                gsap.defaults({ ease: "power4", duration: 1.5 });
                gsap.set('.slide-animation', { y: '15%', opacity: 0 });

                ScrollTrigger.batch('.slide-animation', {
                    onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.2, overwrite: true }),
                });

            }
        });
    }
};

export function blumeAnimation() {
    const blume = document.getElementsByClassName('blume-img')[0];

    if (blume) {
        gsap.from(blume, {
            scrollTrigger: {
                trigger: blume,
            },
            transform: 'rotateZ(-30deg)',
            duration: 2,
            opacity: 0
        });
    }
}

export function underlineAnimation() {
    const underlineBig = [...document.getElementsByClassName('underline-big')];
    const underlineSmall = [...document.getElementsByClassName('underline-small')];
    const underlineElements = [...underlineBig, ...underlineSmall];

    if (underlineElements.length) {

        underlineElements.map((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                },
                width: '3rem',
                duration: 2
            });
        });
    }
}

export function toGrey() {
    const greySection = [...document.getElementsByClassName('section is-grey')];

    if (greySection.length) {
        greySection.map(section => {

            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom-=100',
                },
                backgroundColor: 'transparent',
                duration: 2,
            });
        })
    }
}