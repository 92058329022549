// import Velocity from "velocity-animate"; use this for complex animation
import { Navigation } from '../js-components/navigation';
import { Accordion } from '../js-components/accordion';
import { loadMorePosts, loadMoreReden } from '../js-components/loadMore';
import { initializeAnimations } from '../js-components/initializeAnimations';
import {Kalender} from "../js-components/kalender";

export default {

  init() {
    // JavaScript to be fired on all pages
    Navigation();
    Accordion();
    initializeAnimations();
    loadMorePosts();
    loadMoreReden();
    Kalender()
  }
}
