export function Accordion() {
    const accordions = [...document.getElementsByClassName('accordion')];
    const accordionB = [...document.getElementsByClassName('accordionB')];
    if (accordions.length) {
        accordions.map((accordion, i) => {
            const collapsibleArea = accordion.getElementsByClassName('is-collapse')[0];
            const actionButton = accordion.getElementsByClassName('collapse')[0];
            const icon = accordion.getElementsByClassName('opening_icon')[0];

            actionButton.addEventListener('click', () => {
                accordion.classList.toggle('is-active');
                collapsibleArea.classList.toggle('is-active');

                if (collapsibleArea.style.maxHeight) {
                    collapsibleArea.style.maxHeight = null;
                    icon.innerHTML = '+';
                } else {
                    collapsibleArea.style.maxHeight = collapsibleArea.scrollHeight + "px";
                    icon.innerHTML = '&#8211;';
                }

                let restAccordions = accordions.slice();
                restAccordions.splice(i, 1);

                restAccordions.map(accordion => {
                    const innerCollapsible = accordion.getElementsByClassName('is-collapse')[0];
                    const innerIcon = accordion.getElementsByClassName('opening_icon')[0];

                    accordion.classList.remove('is-active');
                    innerCollapsible.classList.remove('is-active');
                    innerCollapsible.style.maxHeight = null;
                    innerIcon.innerHTML = '+';
                })

            });
        })
    };
};