import { startbildText, bildText, textBild, linksUndRechts, fadeFromBottom, loopAnimation, blumeAnimation, underlineAnimation, toGrey } from './singleAnimations';

export function initializeAnimations() {
    startbildText();
    bildText();
    textBild();
    // linksUndRechts();
    fadeFromBottom();
    loopAnimation();
    blumeAnimation();
    underlineAnimation();
    toGrey();
};
